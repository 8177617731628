/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import * as useMasks from '../../../common/useMasks';
import { isEmpty } from 'lodash';

const mapChildren = (node, entity) => {
  if (
    node.children &&
    node.children.length > 0 &&
    !node.use_mask.includes(useMasks.PRODUCER) &&
    !node.use_mask.includes(useMasks.CAE_MODEL) &&
    !node.use_mask.includes(useMasks.PLOTS)
  ) {
    let data = {};
    for (const child of node.children) {
      let value = mapChildren(child, entity);
      let key = child.type === "Group" ? child.name : child.type + '-' + child.vkey;
      let displayName = child.name;

      if (child.condition) {
        displayName += ', ' + child.condition;
      }

      if (child.standard_name) {
        displayName += ' (' + child.standard_name + ')';
      }

      if (typeof value == 'number') {
        value = value.toString();
      }

      if (typeof value === 'string') {
        let displayValue = value;
        if (child.unit_name) {
          displayValue += ' ' + child.unit_name;
        }

        data[key] = { title: displayName, value: displayValue, isReadOnly: true };
      } else if (Array.isArray(value)) {
        let displayValue = value.toString();
        if (child.unit_name) {
          displayValue += ' ' + child.unit_name;
        }
        data[key] = { title: displayName, value: displayValue, isReadOnly: true };
      } else if (value && typeof value !== 'undefined' && Object.keys(value).length > 0) {
        data[key] = value;
      }
    }
    return data;
  } else {
    if (node.type !== 'Model' && node.type !== 'Mpt' && node.type !== 'Placeholder' && node.type !== 'Group') {
      return entity.display[node.vkey];
    } else if (node.type === 'Placeholder') {
      if (node.vkey === 17) {
        if (node.use_mask.includes(useMasks.PRODUCER)) {
          return entity.producer?.fullname;
        }
      }
      if (node.vkey === 12) {
        return entity.polymerfamily;
      }
    } else if (node.type === 'Model') {
      let data = {};

      if (node.catalog) {
        if (entity.diamodel) {
          for (let diamodel of entity.diamodel) {
            if (diamodel.diamodel === node.catalog._id) {
              // Found matching diamodel
              if (node.catalog.param) {
                for (let param of node.catalog.param) {
                  if (diamodel.parameter && diamodel.parameter[param.shortname] != null) {
                    data[param.shortname] = {
                      title: param.name,
                      value: diamodel.parameter[param.shortname] + ' ' + param.unit,
                      isReadOnly: true,
                    };
                  }
                }
              }
            }
          }
        }
      }

      if (!isEmpty(data)) {
        return data;
      }
    } else if (node.type === 'Mpt') {
      /*
      console.log('### found plot: ', node);
      console.log('### entity: ', entity);
      */
    }
  }
};

const createData = ({ view, entity, catalogs }) => {
  let data = {};

  if (catalogs && entity && entity.display) {
    for (const catalog of catalogs) {
      if (catalog.children) {
        for (let child of catalog.children) {
          if (child.name === view) data = mapChildren(child, entity);
          else if (view === 'compare' && !child.use_mask.includes(useMasks.PLOTS) && !child.use_mask.includes(useMasks.CAE_MODEL)) {
            data = Object.assign(data, mapChildren(child, entity));
          }
        }
      }
    }
  }

  return data;
};

export default createData;
