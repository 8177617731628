/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { changeDialog } from './dialog';
import { clearSelectedEntities } from './selectedEntities';

import { getApi } from '../api';
import { replaceAppsClassifications, getAdvSearchRanges } from './materials';

export function clearSearch(urlChange = false) {
  return (dispatch) => {
    dispatch({
      type: 'CLEAR_MATERIALS',
      urlChange,
    });
  };
}

export function fetchSearchFail() {
  return (dispatch) => {
    dispatch(changeDialog('SEARCH_FAILED'));

    dispatch({
      type: 'FETCH_FAILED_SEARCH',
    });
  };
}

export function fetchingSearch() {
  return {
    type: 'GETTING_MATERIALS',
  };
}

export function replaceSearchEntities(entities, filterData, tagCounts, totalCount, storedTags, track, offset, success = true) {
  let countMap = new Map();

  tagCounts.forEach((tag) => {
    countMap.set(tag.id, tag.counts);
  });

  return {
    type: 'REPLACE_MATERIALS',
    counts: countMap,
    items: entities,
    filterData: filterData,
    totalCount: totalCount,
    storedTags: storedTags,
    track: track,
    offset: offset,
    success: success,
  };
}

export function replaceFilterData(filterData) {
  return {
    type: 'REPLACE_FILTER_DATA',
    filterData: filterData,
  };
}

export function fetchSearch(filterData, qs, newSortColumn = '', newSortDirection = '', reload = false, signal = null) {
  const api = getApi();

  return (dispatch, getState) => {
    const { roots } = getState();

    if (roots.offset === 0 || reload) {
      dispatch({
        type: 'SET_RANGES_LOADING',
      });

      api.materials
        .getRanges(
          qs,
          roots.searchCatalog,
          roots.unitSystem,
          roots.appContent,
          filterData,
          roots.previousFilterData,
          roots.ranges,
          roots.storedTags,
          roots.language,
          signal
        )
        .then((ranges) => {
          if (ranges) {
            let advSearchRanges = getAdvSearchRanges(ranges, roots.searchCatalog);

            dispatch({
              type: 'REPLACE_RANGES',
              ranges: ranges,
              advSearchRanges: advSearchRanges,
            });
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
            dispatch(fetchSearchFail());
            console.error(error);
          }
        });

      api.materials
        .getFeatureCount(qs, roots.unitSystem, filterData, roots.appContent, roots.language, signal)
        .then((featureCounts) => {
          api.materials
            .getClassifications(qs, roots.appContent, roots.language, roots.unitSystem)
            .then((roots) => {
              dispatch(replaceAppsClassifications(roots, featureCounts));
            })
            .catch((error) => {
              if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
                dispatch(fetchSearchFail());
                console.error(error);
              }
            });
        })
        .catch((error) => {
          if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
            dispatch(fetchSearchFail());
            console.error(error);
          }
        });
    }

    dispatch(clearSelectedEntities());
    dispatch(fetchingSearch());

    api.materials
      .getMaterials({
        qs: qs,
        tableColumns: roots.tableColumns,
        classifications: roots.classifications,
        pageSize: roots.pageSize,
        offset: roots.offset,
        sort: newSortColumn,
        sortdir: newSortDirection,
        filterData,
        view: roots.entityView,
        appContent: roots.appContent,
        preferences: roots.preferences,
        language: roots.language,
        unitSystem: roots.unitSystem,
        signal,
      })
      .then((roots) => {
        let tagCounts = [];

        if (roots.counts && roots.counts.tagCounts && roots.counts.tagCounts.individualTagCounts) {
          for (const tagKey in roots.counts.tagCounts.individualTagCounts) {
            tagCounts.push(roots.counts.tagCounts.individualTagCounts[tagKey]);
          }
        }
        dispatch(
          replaceSearchEntities(roots.data, filterData, tagCounts, roots.total, roots.storedTags, roots.track, roots.offset, roots.success)
        );
      })
      .catch((error) => {
        if (error.name !== 'AbortError' && error.name !== 'CanceledError') {
          dispatch(fetchSearchFail());
          throw error;
        }
      });
  };
}
